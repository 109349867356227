import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { GlobalStyle } from '../util/styles'
import {Im} from '../components/PageTitle/styles'
import galaxy from '../images/galaxy.png'
import guts from '../images/Guts.gif'
import {PPost} from '../components/PostExcerpt/styles'
import {Ptd, Ptd2} from '../components/PageTitle/styles'





const AboutPage = () => (
  <>
  <Im src={galaxy}  />
  <Ptd2 >
          </Ptd2>
  <Layout pageTitle="About us">
  
    <GlobalStyle></GlobalStyle>
    <PPost style={{ minHeight: '100%'}}>
    <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />

    
<div className='about '>
<img  style={{ position: 'relative', maxWidth: '100%'   }} src={guts} alt="Guts" />
    <p style={{ textAlign: 'center', maxWidth: '100%', marginTop:'1em'  }} >
    He died doing what he wanted,<br/>
    no matter what, right? <br/>
      I bet he was happy.<br/>
   Of course, once you die,that’s it. <br/>
    Nothing comes after that. <br/>
    Nothing at all.<br/>
    WT<br/>
    

    </p>
    </div>
  
    </PPost>
  </Layout>
  </>
  

  
)



export default AboutPage
